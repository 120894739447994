<template>
  <VueTimepicker
    @change="onTimeChanged"
    :class="{
      'dark' : dark,
      'with-icon': showIcon,
      'inline': inline,
      'full-width': enableFullWidth
    }"
    :format="format"
    :placeholder="placeholder"
    :hour-range="hourRange"
    :hour-interval="hourInterval"
    :minute-range="minuteRange"
    :minute-interval="minuteInterval"
    :drop-direction="dropDirection"
    :container-id="containerId"
    :input-width="fullwidth"
    :value="value">
    <template v-if="showIcon" v-slot:icon>
      <i class="fas fa-clock clock-icon"></i>
    </template>
  </VueTimepicker>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

export default {
  props: {
    format: {
      type: String,
      required: false,
      default: 'HH:mm',
    },
    value: {
      type: String || Object,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'เลือกเวลา',
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    hourRange: {
      type: Array,
      required: false,
    },
    hourInterval: {
      type: Number,
      required: false,
    },
    minuteRange: {
      type: Array,
      required: false,
    },
    minuteInterval: {
      type: Number,
      required: false,
    },
    enableFullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullwidth: {
      type: String,
      required: false,
      default: '',
    },
    dropDirection: {
      type: String,
      required: false,
      default: 'down',
    },
    containerId: {
      type: String,
      required: false,
    },
  },
  components: {
    VueTimepicker,
  },
  methods: {
    onTimeChanged(eventData) {
      this.$emit('onChange', eventData);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';

::v-deep {
  display: block;
  &.inline {
    display: inline-block;
  }
}

::v-deep input.display-time {
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: $font-family-base;
  font-size: $font-size-base * 0.95;
  font-weight: 500;
  margin: $spacer * 0.5 0;
  padding-top: $spacer * 0.5;
  padding-bottom: $spacer * 0.5;
  height: auto;
  color: $body-color;
  background: $gray-200;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 5px;
  border-radius: $border-radius-base;
  border: none;
  &:focus {
    outline: none;
    border: none;
  }
}

::v-deep .custom-icon {
  left: 12px;
}

.clock-icon {
  color: $body-color;
}

.dark ::v-deep input.display-time {
  background: #5e626a;
  color: $gray-200;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 5px;
}

.dark .clock-icon {
  color: $gray-200;
}

.with-icon ::v-deep input.display-time {
  padding-left: 38px;
}

.full-width ::v-deep input.display-time {
  margin: 0;
  width: 100%;
  border-radius: 5px;
  background-color: #F2F3F4;
}

::v-deep .hint {
  display: none;
}

.vue__time-picker {
  width: 100%;
  max-width: 132px;
}

::v-deep .dropdown, ::v-deep .vue__time-picker-dropdown {
  height: 200px;
  width: 132px;
  left: 0;
  top: 42px;
}

::v-deep .dropdown .select-list, ::v-deep .select-list {
  height: 200px;
  width: 132px;
}

::v-deep .select-list ul::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F5F5F5;
}

::v-deep .select-list ul::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #F5F5F5;
}

::v-deep .select-list ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $gray-600;
}

::v-deep .dropdown ul li:not([disabled]).active,
::v-deep .dropdown ul li:not([disabled]).active:hover,
::v-deep .dropdown ul li:not([disabled]).active:focus,
::v-deep ul li:not([disabled]).active,
::v-deep ul li:not([disabled]).active:hover,
::v-deep ul li:not([disabled]).active:focus {
  background: $primary;
  color: #fff;
}

::v-deep .clear-btn {
  display: none;
}
</style>
