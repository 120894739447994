<template>
  <Section class="program-table-container">
    <div class="section-header" slot="header">
      <div
        :class="{'single-title': !subTitle}"
        class="header-group">
        <h1 v-if="title" class="title">{{title}}</h1>
        <h2 v-if="subTitle" class="sub-title">{{subTitle}}</h2>
      </div>
      <div class="summary-type action">
        <button
          @click.stop="onExportCSV"
          type="button"
          class="btn btn-primary btn-export-csv mr-2">
          <span><i class="fas fa-download mr-2"></i>Export CSV</span>
        </button>
        <select
          @change="onSortChange"
          class="custom-select"
          v-model="sortBy">
          <option :value="null" selected disabled hidden>Sort By</option>
          <option
            v-for="(value, index) in sortedKeys"
            :value="value"
            :key="index">
            {{getTableHeaderByName(value)}}
          </option>
        </select>
      </div>
    </div>
    <div class="section-body top-list">
      <table class="table table-borderless table-hover">
        <tr class="table-row">
          <th
            v-for="(header, index) in header"
            :key="index"
            scope="col"
            class="header desktop-cell"
            :class="{'platform-ccu': index > 2}">
            {{getTableHeaderByName(header.name)}}
          </th>
        </tr>
        <tr
          v-for="(data, index) in sortedData"
          :key="index"
          :class="`table-row ${isMyStation(data.stationName) ? 'my-station' : ''}`">
          <th
            class="header desktop-cell"
            scope="row">
            <p class="rank">{{index+1}}</p>
          </th>
          <td class="name desktop-cell">
            <div class="title">
              {{getStationDisplayName(data.stationName)}}
              <span v-if="isMyStation(data.stationName)" class="my-station-text">
                (My Station)
              </span>
            </div>
          </td>
          <td class="program-name desktop-cell">
            <div class="title">{{getProgramTitle(data)}}</div>
            <div class="time">{{formatTableDate(data.startTime, data.endTime)}}</div>
          </td>
          <td
            v-for="(key, index) in sortedKeys"
            :class="{
              'hide': shouldHideCell(key),
              'official-ccu': key === 'officialPlatform',
              'total-ccu': key === 'totalViewCount',
            }"
            class="platform-ccu desktop-cell"
            :key="index">
            {{getPlatformCCU(data, key, true)}}
          </td>
          <td class="mobile-ccu-container mobile-cell with-station-name">
            <div class="mobile-station-info">
              <p class="mobile-rank">{{index+1}}</p>
              <p class="mobile-station-name">
                {{getStationDisplayName(data.stationName)}}
                <span v-if="isMyStation(data.stationName)" class="my-station-text">
                  (My Station)
                </span>
              </p>
              <div class="info-group">
                <p class="program-name">{{data.title}}</p>
                <p class="program-time">{{formatTableDate(data.startTime, data.endTime)}}</p>
              </div>
            </div>
            <div class="total-ccu-row">
              <span class="platform-name">
                Total:
              </span>
              <span class="platform-value">
                {{numberWithCommas(data.totalViewCount)}}
              </span>
            </div>
            <div
              class="mobile-platform-ccu"
              v-for="(key, index) in sortedKeys"
              :key="index">
              <template v-if="key !== 'totalViewCount'">
                <span class="platform-name">
                  {{key === 'officialPlatform' ? 'Official' : getTableHeaderByName(key)}}
                </span>
                <span class="platform-value">
                  {{getPlatformCCU(data, key, true)}}
                </span>
              </template>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </Section>
</template>

<script>
import _ from 'lodash';
import Section from '@/components/Section.vue';
import { getTableHeaderByName } from '@/utils/tableUtils';
import { getStationDisplayName } from '@/utils/stationUtils';
import { formatTableDate } from '@/utils/dateFormatter';
import { numberWithCommas } from '@/utils/numberUtils';

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    header: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
      default: '',
    },
    sortedKeys: {
      type: Array,
      required: false,
      default: () => ['web', 'app', 'officialPlatform', 'trueId', 'totalViewCount'],
    },
    userStation: {
      type: String,
      required: false,
      default: 'ch3Plus',
    },
  },
  components: {
    Section,
  },
  data() {
    return {
      sortedData: null,
      sortBy: null,
    };
  },
  computed: {
    platforms() {
      return _.get(this.$route, 'query.platforms', null);
    },
  },
  created() {
    this.sortedData = _.cloneDeep(this.data);
  },
  methods: {
    getTableHeaderByName,
    getStationDisplayName,
    numberWithCommas,
    formatTableDate,
    shouldHideCell(key) {
      if (this.platforms === 'app,web' && key === 'trueId') {
        return true;
      }
      if (this.platforms === 'trueId' && key === 'web') {
        return true;
      }
      if (this.platforms === 'trueId' && key === 'app') {
        return true;
      }
      return this.platforms === 'trueId' && key === 'officialPlatform';
    },
    getProgramTitle(data) {
      return _.get(data, 'title', '-');
    },
    getPlatformCCU(data, key, formatValue = false) {
      const hasData = _.get(data, key, false);
      let value = null;
      if (data[key] === 0) {
        value = 0;
      }
      if (hasData) {
        value = data[key];
      }
      if (formatValue) {
        return (value >= 0 && value != null) ? numberWithCommas(value) : '-';
      }
      return value;
    },
    isMyStation(stationName) {
      return stationName === this.userStation;
    },
    onSortChange() {
      this.sortedData = _.orderBy(this.data, data => this.getPlatformCCU(data, this.sortBy), ['desc']);
    },
    onExportCSV() {
      this.$emit('export');
    },
  },
};
</script>


<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/table.scss';

.hide {
  display: none;
}
</style>
